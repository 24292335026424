#leaflet-map {
  width: 100%;
  height: 750px;
  // float: left;
  border: solid 1px #dddddd;
  border-radius: 15px;
}

.leaflet-map-secondary {
  width: 19%;
  height: 450px;
  float: left;
  padding: 0 0 0 15px;
  border: solid 1px black;
  border-left: none;
  position: relative;
}

.leaflet-map-secondary h3 {
  font-size: 13px;
  line-height: 15px;
}

.leaflet-map-secondary .regions {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leaflet-map-secondary .regions li {
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
}

.leaflet-map-secondary .legend {
  position: absolute;
  bottom: 0;
}

.leaflet-map-secondary .legend-key {
  list-style: none;
  padding: 0 0 0 5px;
  margin: 0;
}

.leaflet-map-secondary .legend-key li {
  padding: 0 0 6px 14px;
  margin: 0;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  line-height: 14px;
}

.leaflet-map-secondary .legend-key li img {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: -6px;
}

.leaflet-map-secondary .legend-key li>img.icon-recycling {
  top: -2px;
}

.leaflet-map-secondary .legend-key li>img.icon-other {
  top: 6px;
}

.leaflet-map-secondary .legend-divisions {
  list-style: none;
  padding: 0 0 0 5px;
  margin: 0;
}

.leaflet-map-secondary .legend-divisions li {
  padding: 0 0 6px 14px;
  margin: 0;
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  line-height: 14px;
}

.leaflet-map-secondary .legend-divisions li img {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 1px;
  left: -6px;
}

/* Default leaflet styling */

.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container img {
  max-width: none !important;
}

/* stupid Android 2 doesn't understand "max-width: none" properly */

.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-tile-pane {
  z-index: 2;
}

.leaflet-objects-pane {
  z-index: 3;
}

.leaflet-overlay-pane {
  z-index: 4;
}

.leaflet-shadow-pane {
  z-index: 5;
}

.leaflet-marker-pane {
  z-index: 6;
}

.leaflet-popup-pane {
  z-index: 7;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-clickable {
  cursor: pointer;
}

.leaflet-container {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* visual tweaks */

.leaflet-container {
  background: white;
  outline: 0;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #3388ff;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */


/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbbbbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px;
}

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: white;
  border-radius: 5px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333333;
  background: black;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #dddddd;
  margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: white;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: white;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 10px 15px 10px 15px;
  line-height: 1.2;
}

.leaflet-popup-content b {
  line-height: 1.1;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: 700;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999999;
}

/* div icon */

.leaflet-div-icon {
  background: white;
  border: 1px solid #666666;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-in;
  -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-in;
  -o-transition: -o-transform 0.2s ease-out, opacity 0.2s ease-in;
  transition: transform 0.2s ease-out, opacity 0.2s ease-in;
}

.leaflet-popup-content-wrapper {
  font-family: 'Inter', sans-serif !important;
  border-radius: 8px;

  b {
    color: #495060;
    font-size: 14px;
    font-weight: normal;
  }

  font-size:11px;
  color:#676D7C;

  .leaflet-popup-content {
    margin: 20px 20px 10px 20px;
    line-height: 20px;
    min-width: 200px;
  }

  a {
    color: #006486;
    text-decoration: none;
    font-size: 13px;
    padding-right: 22px;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" x="0" y="0" viewBox="0 0 6.35 6.35" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><path d="M.534 3.44h4.648l-.871.87c-.25.25.125.626.375.376l.793-.794.53-.53a.265.265 0 0 0 0-.374L4.685 1.664a.265.265 0 0 0-.192-.08.265.265 0 0 0-.183.456l.873.87H.52c-.367.019-.34.548.014.53z" fill="%23006486" data-original="%23000000" opacity="1" class=""></path></svg>') no-repeat right 2px;

    &:hover {
      color: #e84608;
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" x="0" y="0" viewBox="0 0 6.35 6.35" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><path d="M.534 3.44h4.648l-.871.87c-.25.25.125.626.375.376l.793-.794.53-.53a.265.265 0 0 0 0-.374L4.685 1.664a.265.265 0 0 0-.192-.08.265.265 0 0 0-.183.456l.873.87H.52c-.367.019-.34.548.014.53z" fill="%23e84608" data-original="%23000000" opacity="1" class=""></path></svg>') no-repeat right 2px;

    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ddd;
      margin: 10px 0 10px 0;
    }
  }
}

.leaflet-popup-tip-container {
  margin: auto;
  margin-left: -20px;
  height: 7px;

  .leaflet-popup-tip {
    width: 10px;
    height: 10px;
    margin: -5px auto 0;
  }
}