@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600;700&display=swap');
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "ngx-smart-modal/ngx-smart-modal";
$primary-color: #00577A;
$primary-color-h: #014a63;
$secondary-color: #E34300;
$secondary-color-h: #CE3900;
$primary-text: #717171;
$secondary-text: #2F3E4A;
$orange: #E34300;
$white: #fff;
$white-8: rgba(255, 255, 255, .8);
$white-5: rgba(255, 255, 255, .5);
$white-1: rgba(255, 255, 255, .1);
$black: #000;
$gray: #6c757d;
$gray-dark: #343a40;
$red: #dc3545;
$green: #198754;
$light-gray: #fbfbfb;
$light-blue: rgba(0, 119, 217, 0.07);
$dark-color: #002430;

$primary-font: 'Inter', sans-serif;
$border-radius-primary: 0px;
$border-radius-secondary: 0px;
$box-shadow: 0px 5px 35px 5px rgba(0, 0, 0, 0.05);

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: $primary-font;
  vertical-align: baseline;
}

body {
  font-size: 18px;
  line-height: 30px;
  color: $primary-text;
  background: $white;
}

html,
body,
ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.container {
  max-width: 100%;
}

.container,
header .container-fluid {
  padding-left: 5%;
  padding-right: 5%;
}

.br-15 {
  border-radius: $border-radius-secondary;
}

a {
  color: $secondary-color;

  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

/* buttons style */

.btn-primary {
  background: $primary-color;
  border: 0px;
  padding: 10px 20px 8px 20px;

  &:hover {
    background: $primary-color-h;
  }
}

.btn-secondary {
  background: $secondary-color;
  font-size: 18px;
  font-weight: 600;
  padding: 13px 40px 12px 40px;
  position: relative;
  border-radius: $border-radius-primary;
  border: 0px;
  display: inline-block;

  &:hover {
    background: $secondary-color-h;
  }
}

.btn-outline-primary {
  font-size: 18px;
  font-weight: 600;
  padding: 13px 40px 12px 40px;
  border-color: $primary-color;
  color: $primary-color;

  &:hover,
  &:active,
  &:focus {
    background: $primary-color !important;
    color: $white;
    border-color: $primary-color !important;
  }
}

.btn-secondary.btn-icon {
  padding-right: 55px;

  span {
    display: block;
    position: absolute;
    right: 3px;
    top: 3px;
    background: $white;
    border-radius: $border-radius-primary;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  svg path {
    fill: $secondary-color;
  }
}

.btn-primary.btn-sq {
  width: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0;
}

.btn-lg.btn-secondary {
  padding: 18px 30px;
}


/* Header section */

header a {
  text-decoration: none;
}

.top-nav {
  color: $secondary-text;
  background: $light-gray;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;

  ul {
    margin: 0;
    padding: 0;
    text-align: right;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }

  a {
    color: $secondary-text;

    &:hover {
      color: $secondary-color;
    }
  }
}

.navbar-brand {
  width: 100%;
  max-width: 300px;
}

.navbar {
  box-shadow: $box-shadow;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;

  .nav-item {
    margin: 0 15px;


    &:last-child {
      margin-right: 0;
    }

    @media only screen and (min-width:992px) {
      &:hover ul {
        display: block;
      }

      &:hover {
        .nav-link {
          color: $secondary-color;
        }
      }
    }


    .dropdown-menu {
      padding: 0px;
      min-width: 270px;
      border-radius: 0 0 0px 0px;
      box-shadow: $box-shadow;
      border: 0;

      ul {
        display: none;
      }

      li {
        &:hover ul {
          display: block;
        }
      }

      b {
        color: $primary-color;
        padding: 8px 15px;
        font-size: 15px;
        display: block;
      }

      a {
        display: block;
        color: $secondary-text;
        padding: 10px 25px;
        font-size: 18px;

        &:hover {
          background: $light-blue;
          color: $primary-color;
        }
      }

      .dropdown-menu {
        right: 100%;
        top: 0;
      }
    }

    .dropdown-menu-end {
      left: auto;
      right: 0;
    }

    .dropdown-toggle:after {
      display: none;
    }
  }



  .nav-link {
    color: $secondary-text;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: $secondary-color;
    }
  }
}

@media only screen and (max-width:992px) {
  .navbar {
    padding: 0px;
  }

  .navbar-brand {
    margin: 5px 0;
  }

  .navbar-collapse {
    margin-left: -30px;
    margin-right: -30px;
    background: $primary-color;
  }

  .navbar .nav-item {
    margin: 0px;
  }

  .navbar .nav-link {
    padding: 15px 30px;
    color: $white;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
      color: $white;
    }
  }

  .navbar .nav-item .dropdown-menu {
    background: $primary-color;
    border-radius: 0;
    margin: 0;
  }

  .navbar .nav-item .dropdown-menu a {
    background: rgba(0, 0, 0, .1);
    color: #fff;
    padding: 6px 30px;

    &:hover {
      background-color: $secondary-color;
      color: $white;
    }
  }
}

@media only screen and (max-width:576px) {
  .navbar-collapse {
    margin-left: -20px;
    margin-right: -20px;
    background: $primary-color;
  }
}

/* footer section */

footer {
  background: $dark-color;
  padding-top: 50px;
  padding-bottom: 50px;
  color: $white-8;
  font-size: 16px;

  h6 {
    font-size: 26px;
    font-weight: 600;
    color: $white;
    margin: 0px;
    padding: 0 0 10px 0;

    a {
      color: $white;
      font-size: 22px;
    }
  }

  ul {
    padding-left: 12px;
  }

  b {
    font-size: 19px;
    font-weight: 400;
    color: $white
  }

  p {
    font-size: 16px;
    color: $white-8;
    margin-bottom: 15px;
  }

  a {
    color: $white-8;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  .call-info svg {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }

  .fot-logo {
    max-width: 225px;
  }
}

.row.footer-text {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, .1);
  margin-top: 30px;
}

.footer-nav {
  text-align: right;

  li {
    display: inline-block;
    list-style: none;
    padding-left: 5px;
  }
}

.follow-us {
  a {
    display: inline-block;
    border-radius: $border-radius-primary;
    width: 54px;
    height: 54px;
    line-height: 54px;
    background: $white-1;
    text-align: center;
    margin-right: 14px;
    margin-top: 5px;

    &:hover {
      background: rgba(0, 0, 0, .1);
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  svg {
    width: 25px;
    height: 25px;

    path {
      fill: $white;
    }
  }

  .youtube svg {
    margin-top: 7px;
  }
}

#cookie-footer {
  padding: 0px !important;
  position: absolute;
  right: 70px;
  margin-top: -48px;
  font-size: 14px;
  color: $white-8;
  z-index: 2;

  li {
    list-style: none;
    display: inline-block;
  }

  a {
    color: $white-8;

    &:hover {
      color: $white
    }
  }
}

.title {
  h1 {
    font-weight: 600;
    font-size: 120px;
    margin: 0 0 30px 0;
  }

  span {
    font-size: 45px;
    font-weight: 300;
  }
}

.rounded-title {
  img {
    border-radius: $border-radius-secondary;
    width: 100%;
  }

  h3 {
    height: 100%;
    background: $primary-color;
    border-radius: $border-radius-secondary;
    font-size: 35px;
    padding: 40px;
    color: $white;
    align-items: end;
    display: flex;
    text-transform: uppercase;
    font-weight: 700;

    &:after {
      background: $secondary-color;
      height: 4px;
      width: 60px;
      content: "";
      position: absolute;
      bottom: 30px;
    }
  }
}

.s-border {
  position: relative;
}

.mt-30 {
  margin-top: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.ptb-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

h2 {
  color: $primary-color;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 45px;

  span {
    color: $secondary-color;
  }
}

.title-secondary {
  color: $secondary-text;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
}

h3 {
  color: $secondary-text;
  font-size: 35px;
  margin-bottom: 10px;
}

section p {
  margin-bottom: 20px;
}

.lb-bg {
  background: $light-blue;
}

.tc-secondary {
  color: $secondary-text;
}

.blue-bg {
  background: $primary-color;
  color: $white;

  h2 {
    color: $white;
  }

  a {
    color: $white;
    font-weight: 700;
  }
}

.w-box {
  background: $white;
  border-radius: $border-radius-secondary;
  box-shadow: $box-shadow;
  padding: 40px;
}


.buy-from-us {
  text-align: center;
  background: $primary-color url(/assets/images/buy-from-us-bg.jpg) no-repeat center center / cover;
  color: $white;

  .white-bg {
    background: $white-1;
    padding: 40px;
    height: 100%;

    .b-text {
      font-size: 35px;
      font-weight: 700;

      span {
        color: #89B1D2;
      }
    }

    p {
      color: $white-8;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .col-md-4 {
    margin-bottom: 30px;
  }

  h2,
  p {
    color: $white;
  }
}

.blue-bg-sq {
  &:after {
    background: $light-blue;
    content: "";
    width: 300px;
    height: 400px;
    position: absolute;
    left: -100px;
    top: -100px;
    z-index: -1;
  }
}

.blue-bg-sq-big {
  &:after {
    background: $light-blue;
    content: "";
    width: 60%;
    height: 400px;
    position: absolute;
    right: -85px;
    top: -100px;
    z-index: -1;
  }
}

.services {
  .shadow {
    border-radius: $border-radius-secondary;
    overflow: hidden;
    height: 100%;
    text-align: center;
    background: $white;
    z-index: 2;
    position: relative;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 15px;
    color: $secondary-text;
    font-weight: 600;
  }
}

.form-label {
  margin-bottom: 10px;
}

.form-control {
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border-radius: 0px;
}

.img-money,
.img-crane {
  display: block;
  background-repeat: no-repeat;
  box-shadow: $box-shadow;
  border-radius: $border-radius-secondary;
  padding: 20px;
  background-position: 95% center;
  border: 2px solid $white;
  color: $secondary-text;
  text-decoration: none;

  h3 {
    margin-bottom: 5px;
  }

  &:hover {
    text-decoration: none;
    border-color: $primary-color;
    color: $primary-color;
  }

  p {
    margin: 0;
    color: $primary-text;
  }

  span {
    background: $light-blue;
    display: block;
    border-radius: 0px;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    margin-bottom: 15px;
  }
}

.img-money {
  background-image: url(/assets/images/general-public-icon-b.svg);
}

.img-crane {
  background-image: url(/assets/images/businesses-icon-b.svg);
}

.news-release-row {
  a {
    color: $primary-color;
    font-weight: 600;

    &:hover {
      color: $primary-color-h;
    }
  }
}

.stock_quote {

  h3,
  h4 {
    font-weight: 600;
  }

  h3 span {
    color: $primary-color;
  }

  h4 {
    padding-left: 20px;
  }

  .stock_up {
    color: #390;
    background: url(/assets/images/stock_bullet_up.gif) 5px 7px no-repeat;
  }

  .stock_down {
    color: $red;
    background: url(/assets/images/stock_bullet_down.gif) 5px 7px no-repeat;
  }
}

.fal-h {
  h1 {
    margin-bottom: 20px;
    color: $primary-color;
    font-size: 40px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }
}

/* bread crumb */
#bread_crumb_container {
  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }

    &:after {
      content: "\003E";
      padding: 0 6px 0 7px;
      display: inline-block;
    }
  }

  img {
    display: none;
  }
}

.scrap-spacer-header {
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
}

.scrap-spacer-footer {
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
}

#left_middle_content_container {
  .col-md-4 {
    width: 20%;
  }

  .col-md-8 {
    width: 80%;
    padding-left: 40px;
  }
}

#content {
  h1 {
    color: $secondary-text;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .right_col {
    float: right;
    clear: right;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 7px;

    img {
      border-radius: $border-radius-secondary;
      box-shadow: $box-shadow;
      min-width: 300px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  a {
    color: $secondary-color;

    &:hover {
      text-decoration: none;
      color: $primary-color;
    }
  }

  #sell_to_us_links {
    padding: 20px;
    box-shadow: $box-shadow;
    border-radius: $border-radius-secondary;
    width: 300px;

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
        color: $secondary-color;
      }
    }

    ul {
      margin-left: 20px;
      color: $primary-color;
      margin-top: 10px;
    }
  }
}

a.button_link,
a.return_link {
  background: $secondary-color;
  font-size: 15px;
  font-weight: 400;
  padding: 14px 20px;
  position: relative;
  border-radius: 0px;
  padding-left: 55px;
  line-height: 14px;
  display: inline-block;
  color: $white !important;
  position: relative;
  text-decoration: none !important;

  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' x='0' y='0' viewBox='0 0 24 24' style='enable-background:new 0 0 20 20' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M22 11H4.414l5.293-5.293a1 1 0 1 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L4.414 13H22a1 1 0 0 0 0-2z' fill='%23EE6C4D' data-original='%23EE6C4D' class=''%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    content: "";
    display: block;
    position: absolute;
    left: 11px;
    top: 12px;
    z-index: 2;
    width: 20px;
    height: 23px;
  }

  &:before {
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
    background: $white;
    border-radius: 0px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    content: "";
    display: block;
    z-index: 1;
  }
}


a.button_link:hover,
a.return_link:hover {
  background: #ce5437;
  text-decoration: none;
}

/* treeview nav container */
#treeview_nav_container {
  padding: 30px;
  border-radius: $border-radius-secondary;
  position: relative;
  z-index: 2;
  background: $light-blue;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
    }
  }

  a {
    color: $primary-color;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img.extlink {
    vertical-align: middle;
    margin: -2px 0 0 5px;
    border: none;
  }

  #treeview_nav_current_item {
    position: relative;

    a:hover {
      background: transparent;
    }

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid $white;
      border-top: 0;
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -5px;
    }
  }

  .treeview_nav_section2 {
    li {
      margin: 0;
    }

    a {
      margin: 0;
      padding: 8px 15px 8px 15px;
      text-decoration: none;

      &:hover {
        background: rgba(0, 0, 0, .1);
        border-radius: $border-radius-primary;
      }
    }

    #treeview_nav_open_item {
      span {
        font-weight: 700;
        display: block;
      }
    }
  }

  .treeview_nav_section3 {
    padding: 5px;
    background: $primary-color;
    border-radius: $border-radius-secondary;
    position: relative;
    display: none;

    &:after {
      bottom: 100%;
      left: 15px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $primary-color;
      border-width: 10px;
    }

    li {
      margin: 0;
    }

    a {
      margin: 0;
      padding: 0px 0 0px 10px;
      text-transform: none;
      color: $white;
      font-size: 14px;
      line-height: 34px;

      &:hover {
        text-decoration: none;
        color: $secondary-color;
      }
    }

    .treeview_nav_current_item {
      border-radius: $border-radius-primary;
      position: relative;
      color: $white;
      background: $secondary-color;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  .treeview_nav_section4 {
    border-radius: $border-radius-secondary;
    position: relative;
    color: $white;
    background: rgba(0, 0, 0, .1);
    margin-top: 10px;

    li {
      margin: 0;
      padding: 0;
    }

    &:after {
      bottom: 100%;
      left: 10px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: rgba(0, 0, 0, .1);
      border-width: 10px;
    }

    a {
      margin: 0;
      padding: 2px 3px 2px 20px;
      font-weight: 400;
      font-size: 14px;
      border-left: 3px solid transparent;
      border-radius: 0;
    }

    span#treeview_nav_current_item {
      display: block;
      margin: 0;
      padding: 0;
    }

    .treeview_nav_current_item {
      color: $secondary-color;
      border-color: $secondary-color;
      background: transparent;
      border-radius: 0;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .treeview_nav_section5 {
    margin: 0;

    li {
      background-image: none;
      padding: 0 0 0 16px;
    }

    a {
      padding: 3px 4px 0 20px;
      font-weight: 400;
      font-style: italic;
      color: $white;
    }
  }
}


/* Location Page */
.mw-1600 {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.fl-search {
  .form-label {
    font-weight: 700;
    color: $secondary-text;
    padding-right: 30px;
    font-size: 24px;
    margin: 0;
  }

  div {
    position: relative;

    i {
      position: absolute;
      top: 24px;
      left: 15px;
      font-size: 24px;
    }

    .form-control {
      background: #F2F4FB;
      border: 0px;
      margin: 0;
      height: 70px;
      width: 755px;
      padding-left: 50px;
    }
  }
}

.location_map {
  margin-bottom: 20px;
  margin-top: 40px;
}

.location-list {
  .tab-content {
    padding-top: 40px;

    .location_column1 {
      width: 25%;
      display: inline-block;
      vertical-align: top;

      ul {
        margin: 0px;
        padding: 0 0 30px 0;

        li {
          list-style: none;
          padding: 5px 40px 5px 0;

          a {
            display: block;
            position: relative;
            font-size: 17px;
            padding-right: 40px;
          }

          &.icon-inline {
            i {
              top: auto;
              bottom: 7px;
              color: #9da5b7;
              font-size: 12px;
            }
          }

          i {
            position: absolute;
            right: 0;
            top: 8px;
            color: #9da5b7;
            font-size: 12px;
          }
        }
      }
    }

    strong,
    h4 {
      color: $secondary-text !important;
      font-size: 18px;
    }

    h4 {
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;
      color: $primary-text;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &.es {
    .nav-pills .nav-link {
      font-size: 14px;
    }

    .title-secondary {
      font-size: 25px;
    }
  }
}

.legend {
  ul {
    margin: 0px;
    padding: 0px;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 18px;
      font-size: 15px;

      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 0px;
        margin-right: 10px;
        margin-bottom: 2px;
        vertical-align: middle;

        &.mr {
          background: $primary-color;
        }

        &.ap {
          background: #FFDE00;
        }

        &.css {
          background: $secondary-color;
        }

        &.corporate {
          background: #002430;
        }
      }

    }
  }
}

.line-h-0 {
  line-height: 0;
}

.location-details {

  h2,
  h3 {
    margin: 0px 0 15px 0;
    font-size: 20px;
    color: $secondary-text;
  }

  h3 {
    margin-top: 40px;
  }

  p {
    margin-bottom: 15px;
  }

  i {
    color: #9BA6B8;
    margin-right: 10px;
  }

  .btn {
    margin-top: 20px;
  }

  .btn-secondary {
    margin-right: 20px;
  }

  .address {
    a {
      color: $primary-text;
      text-decoration: none;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .hours {
    margin-bottom: 40px;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding-bottom: 10px;
      }
    }

    label {
      min-width: 190px;
    }

    span {
      padding-right: 20px;
    }
  }
}
.abt-info{
  ul{margin-left:20px;
   ul{margin-left:20px; margin-bottom:20px;}
  }
}
.visitor-info {
  margin-top: 40px;

  h2 {
    font-size: 20px;
    color: $secondary-text;
    margin: 0px;
  }

  h5 {
    border-radius: 0px 0px 0 0;
    color: $secondary-text;
    background: $light-blue;
    margin-top: 20px;
    padding: 23px 30px;
    position: relative;

    span {
      position: absolute;
      top: 17px;
      right: 15px;
      width: 35px;
      height: 35px;
      background: $white;
      border-radius: $border-radius-primary;
      text-align: center;
      color: $primary-color;

      i {
        line-height: 35px;
      }
    }

    .fa-angle-right {
      display: none;
    }

    .fa-angle-up {
      display: block;
    }
  }

  h5.collapsed {
    border-radius: 0px;

    .fa-angle-right {
      display: block;
    }

    .fa-angle-up {
      display: none;
    }
  }

  .collapse {
    background: $white;
  }

  .card {
    border: 0px;
    background: $light-blue;
    padding: 0 30px 30px 30px;
    border-radius: 0 0px 0px 0px;
    color: $secondary-text;

    ul {
      margin-left: 33px;

      li {
        list-style: none;
        padding: 5px 0;
        position: relative;

        &:after {
          width: 15px;
          height: 2px;
          content: "";
          position: absolute;
          top: 18px;
          left: -30px;
          background: #9BA6B8;
        }
      }

    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.d-none {
    &.es {
      display: block !important;
    }
  }
}

/* Home Page */
.formerly-bar {
  text-align: center;
  color: #000;
  background: #fbfbfb;
  padding: 5px 10px;
  font-size: 14px;
}

.find-location {
  text-align: center;
  background: url(/assets/images/location-bg.jpg) no-repeat center center;

  .position-relative {
    max-width: 650px;
    margin: 0 auto 32px auto;

    button {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    p {
      text-align: left;
    }

  }

  .form-control {
    height: 56px;
    margin: 0;
  }
}

.navbar-toggler {
  border: 0px;

  &:focus {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  }

  .navbar-toggler-icon {

    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0,87,122, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

    &:hover {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(227,67,0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
}

.home-title {
  max-width: 510px;
}

.sp-home {
  &.container {
    max-width: 1200px;
  }

  .home-title {
    max-width: 710px;
  }

  .gird-blocks a {
    height: 150px;
  }

  .gird-blocks img {
    height: 100%;
    object-fit: cover;
  }
}

.circular-economy {
  background: url(/assets/images/radius-recycling-bg.jpg) no-repeat center center;

  .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: $white;
    border-radius: $border-radius-primary;
  }
  video{width:100%;}
  .nsm-dialog-btn-close{background: rgba(255,255,255,0.5);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    svg{margin-top: -5px;}}
  .row {
    min-height: 680px;
  }

  .play-btn {
    &:hover {
      opacity: 0.8;
    }
  }
}

.head-vid {
  position: relative;
  height: calc(100vh - 135px);
  overflow: hidden;

  .container {
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  .title {
    display: flex;
    height: 100%;
    align-items: center;

    h1 {
      line-height: 120px;
    }

    span {
      line-height: 45px;
    }
  }

  img {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.gird-blocks {
  img {
    width: 100%;

  }

  a {
    position: relative;
    display: block;
    border-radius: $border-radius-secondary;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(0, 0, 0, .5);
      z-index: 1;
      transition: all 700ms ease
    }

    h4 {
      color: $white;
      text-decoration: none;
      position: absolute;
      z-index: 2;
      top: 0px;
      width: 100%;
      height: 100%;
      font-size: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover:after {
      background: rgba(0, 0, 0, .8);
    }
  }
}

.owl-carousel.owl-drag .owl-item {
  min-height: 210px;
}

.latest_news_item {
  background: linear-gradient(180deg, #EDF5FC 0%, rgba(237, 245, 252, 0) 100%);
  border-radius: $border-radius-secondary;
  padding: 40px 50px;
  position: relative;

  .owl-item {
    cursor: pointer;
  }

  .news-release-row {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: $border-radius-secondary;
    padding: 30px;
    background: #fff;
    min-height: 200px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin: 0;
    }

    a {
      text-decoration: none;
      font-weight: normal;
      display: inline-block;
      margin-bottom: 20px;

      &:hover {
        text-decoration: underline;
        color: $secondary-color;
      }
    }
  }
}

.page-head {
  position: relative;
  background: $primary-color;

  .row {
    min-height: 540px;
  }

  h1 {
    font-size: 60px;
    font-weight: 600;
    color: $white;
    max-width: 100%;

    span {
      display: block;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;

      &:before {
        content: "";
        width: 190px;
        height: 6px;
        background: $secondary-color;
        display: block;
        margin: 10px 0 15px 0;
      }
    }
  }

  img {
    top: 0px;
    left: 50%;
    width: 50%;
    height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
  }
}

.sub-nav {
  #treeview_nav_container {
    background: $secondary-color;
    border-radius: 0;
    text-align: center;
    padding: 10px;
    display: none;

    a {
      color: $white;
    }

    .treeview_nav_section2 {
      display: inline-block;
      margin: 0 2px;
    }
  }

  ul {
    background: $secondary-color;
    border-radius: 0;
    text-align: center;
    padding: 10px;

    li {
      display: inline-block;
      padding: 0 5px;

      a {
        padding: 8px 15px 8px 15px;
        color: $white;
        display: block;
        text-decoration: none;
        position: relative;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-radius: $border-radius-primary;
        }
      }

      .active {
        font-weight: 700;

        &:hover {
          background: transparent;
        }

        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid $white;
          border-top: 0;
          content: "";
          display: block;
          position: absolute;
          bottom: -10px;
          left: 50%;
          margin-left: -5px;
        }
      }
    }
  }
}

.highlights {
  h3 {
    font-size: 30px;
    color: $primary-color;
    text-transform: uppercase;
    margin-bottom: 40px;

    img {
      margin-right: 30px;
    }
  }

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
      color: $secondary-text;
      margin-bottom: 25px;
      position: relative;
      padding-left: 30px;

      span {
        color: $secondary-color;
        font-weight: 700;
      }

      i {
        color: $secondary-color;
        position: absolute;
        top: 4px;
        left: 0px;
        font-size: 20px;
      }
    }
  }
}

.reports {
  background: #002430;
  color: $white;
  overflow: hidden;
  border-radius: $border-radius-secondary;
  position: relative;
  margin-bottom: 70px;

  .row {
    max-width: 990px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  h2 {
    position: relative;
    z-index: 2;
    color: $white;
    margin-bottom: 40px;
  }

  label {
    font-weight: 700;
  }

  img {
    top: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    opacity: 0.1;
    object-position: center;
    z-index: 1;
  }
}

/* investors */
.email-alerts {
  text-align: center;
  background: url(/assets/images/investors/email-alerts.jpg) no-repeat top center;
}

.stock-info {
  border: 3px solid #ddd;
  border-radius: $border-radius-secondary;
  padding: 30px;
  font-size: 24px;
  font-weight: 700;
  color: $black;
  line-height: 38px;

  .btn-secondary {
    padding-bottom: 8px;
    padding-top: 9px;
  }

  h3 {
    color: $primary-color;
    font-size: 28px;
    margin-bottom: 15px;
  }

  .stock_down {
    color: $red;
    font-size: 28px;
    background: url(/assets/images/stock_bullet_down.png) 0 5px no-repeat;
    padding-left: 40px;
    margin-bottom: 15px;
  }

  .stock_up {
    color: #2B9F0F;
    font-size: 28px;
    background: url(/assets/images/stock_bullet_up.png) 0 0px no-repeat;
    padding-left: 40px;
    margin-bottom: 15px;
  }

}

.events {
  .title-secondary {
    span {
      display: block;
      font-size: 18px;
      color: $gray;
      font-weight: 300;
    }

    img {
      margin-right: 20px;
    }
  }

  .events-item {
    border-top: 1px solid #f4f4f4;
    padding: 20px 0;
    font-weight: 700;

    a {
      text-decoration: none;
    }

    .meta-date {
      color: $secondary-text;
    }

    p {
      color: $secondary-text;
      margin: 15px 0 0 0;
    }
  }
}

.investor-materials {
  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
      color: $secondary-text;
      margin: 10px 0 15px 30px;
      position: relative;

      i {
        position: absolute;
        left: -30px;
        top: 6px;
      }

      a {
        color: $secondary-text;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

}

.owl-nav {
  button {
    position: absolute;
    top: 33%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    width: 45px;
    height: 45px;
    background: #fff;
    font-size: 22px;
    border: 0;
    border-radius: $border-radius-primary;
    color: $primary-color;

    &:hover {
      color: $secondary-color;
    }
  }

  .owl-prev {
    left: -22px;
  }

  .owl-next {
    right: -22px;
  }
}

.stock_information {

  overflow: auto;

  table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
    color: $secondary-text;

    th {
      font-weight: 400;
      font-size: 16px;
      vertical-align: top;
      background-color: #e2e2e2;
      color: $secondary-text;
      text-align: left;
      padding: 5px 10px 5px 10px;
      border: 1px solid #c8c8c8;
    }

    td {
      font-weight: 700;
      font-size: 18px;
      text-align: left;
      padding: 5px 10px 5px 10px;
      border: 1px solid #c8c8c8;
      white-space: nowrap;
    }

    h5 {
      margin: 0 0 8px;
      padding: 0;
      font-weight: 400;
      font-size: 15px;
    }

    h4 {
      color: $secondary-text;
      margin: 0 0 20px;
      padding: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    h3 {
      color: $primary-color;
      margin: 0;
      padding: 0;
      font-size: 30px;
      font-weight: 400;
    }

    label {
      display: block;
      padding: 0 0 5px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .stock_down,
  .stock_up {
    padding-left: 14px
  }

  .stock_down {
    color: $red;
    background: url(/assets/images/stock_bullet_down-s.png) 0 7px no-repeat
  }

  .stock_up {
    color: #2B9F0F;
    background: url(/assets/images/stock_bullet_up-s.png) 0 7px no-repeat
  }
}

/* Datatable  */
.datatable-bt {
  overflow: auto;

  .ngx-datatable.material {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0), 0 8px 10px 1px rgba(0, 0, 0, 0), 0 3px 14px 2px rgba(0, 0, 0, 0);
  }

  .ngx-datatable.material .datatable-header {
    border: 0px;
    background: #F6F7FB;
  }

  .ngx-datatable.material .datatable-header .datatable-header-cell {
    background: #F6F7FB;
    color: #676D7C;
    font-size: 18px;
    font-weight: 600;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 18px;
    color: #676D7C;
  }

  .ngx-datatable.material.striped .datatable-row-odd {
    background: #EDF5FC;
  }

  .ngx-datatable.material .datatable-footer {
    font-size: 16px;
  }

  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #E4ECF2;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
    background: $primary-color;
    color: $white;
    border-color: $primary-color;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager a {
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    padding: 0;
    border: 1px solid $primary-color;
    color: $primary-color;
    border-radius: $border-radius-primary;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    background: $white;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
  .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
  .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
  .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
    font-size: 22px;
    line-height: 35px;
    padding: 0;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a,
  .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a:hover {
    border-color: rgba(0, 0, 0, 0.26);
  }

  .docIcon {
    display: inline-block;
    margin-right: 10px;
  }
}

/* Careers */

.half-img {
  position: relative;

  .row {
    min-height: 400px;

    .col-md-6 {
      padding-left: 70px;
    }
  }

  img {
    width: 50%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    object-fit: cover;
  }
}

.awards {
  .plr-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.benefits {
  p {
    max-width: 830px;
    margin: 0 auto;
  }

  .accordion {
    margin-top: 40px;
  }

}

/* Our Company */
.mw-1250 {
  max-width: 1250px;
  margin: 0 auto;
}

.f-22 {
  font-size: 22px;
}

.company-gird {
  margin-top: 50px;

  .col-lg-6 {
    padding-top: 25px;
  }

  .lb-bg {
    padding: 12px;
    cursor: pointer;
    border: 2px solid transparent;
    height: 100%;
    transition: all 0.3s linear;

    a {
      color: $secondary-text;
      text-decoration: none;
    }

    .link-secondary {
      color: $secondary-color !important;
      text-decoration: underline;
      text-decoration-color: $secondary-color !important;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        color: $primary-color !important;
        text-decoration: none;
      }
    }

    &:hover {
      box-shadow: 0px 5px 35px 5px rgba(0, 0, 0, 0.05);
      border-color: $white;
    }
  }

  .bg-white {
    margin-right: 30px;
  }

  h3 {
    color: $primary-color;
    font-size: 22px;
  }

  p {
    margin: 0px;
    color: $secondary-text;
  }
}

.fl-drak {
  background: #002430;
  color: $white;
  overflow: hidden;
  border-radius: $border-radius-secondary;
  position: relative;
  padding: 80px 30px;

  .theme1 {
    position: relative;
    z-index: 2;
  }

  h2 {
    font-size: 40px;
    color: $white;
    margin-bottom: 40px;

    span {
      color: $white;
      padding-right: 10px;
    }
  }

  br {
    display: none;
  }

  img {
    top: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    opacity: 0.1;
    object-position: center;
    z-index: 1;
  }
}

/* Modal LG */
.modal-content {
  border-radius: 0 !important;
}

.modal-lg {
  max-width: 880px;

  h3 {
    font-weight: 400;
    font-size: 35px;
    color: $primary-color;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 22px;
    color: $secondary-color;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .modal-body {
    padding: 40px;
  }

  .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }
}

/* Leadership */
.team-grid {
  margin: 0px -15px 0 -15px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
    width: 25%;
    padding: 15px;
    min-height: 100%;

    .shadow {
      padding: 20px 20px 65px 20px;
      box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05) !important;
      border-radius: $border-radius-secondary;
      border: 1px solid rgba(0, 0, 0, .1);
      height: 100%;
      position: relative;
      transition: all 0.3s linear;

      &:hover {
        box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.10) !important;
      }

      h3 {
        font-size: 22px;
        color: $secondary-text;
        margin: 15px 0 20px 0;
      }

      h4 {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 30px;
      }

      .show-more {
        width: 45px;
        height: 45px;
        display: inline-block;
        background: $secondary-color;
        color: $white;
        line-height: 45px;
        text-align: center;
        border-radius: $border-radius-primary;
        font-size: 24px;
        transition: all 0.3s linear;
        position: absolute;
        bottom: 20px;
        right: 20px;

        &:hover {
          background: $primary-color;
        }
      }
    }

    img {
      width: 100%;
      border-radius: $border-radius-secondary;
    }
  }

  @media only screen and (max-width:992px) {
    li {
      width: 33.33%;
    }
  }

  @media only screen and (max-width:767px) {
    li {
      width: 50%;
    }
  }

  @media only screen and (max-width:576px) {
    li {
      width: 100%;
    }
  }
}

.board-committees {
  h3 {
    font-size: 22px;
    color: $primary-color;
    margin-bottom: 40px;
    min-height: 55px;

  }

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
      color: $secondary-text;
      margin-bottom: 25px;
      position: relative;
      padding-left: 30px;

      &:last-child {
        margin-bottom: 0px;
      }

      i {
        color: $secondary-color;
        position: absolute;
        top: 4px;
        left: 0px;
        font-size: 20px;
      }
    }
  }

}

/* Culture */
.core-values {
  .lb-bg {
    padding: 30px;

    h3 {
      color: $primary-color;
      font-size: 30px;
    }

    img {
      margin: 20px 0 30px 0;
    }

    p {
      margin: 0px;
    }
  }
}

/* Contact page */
.contact {
  h3 {
    color: $primary-color;
    font-size: 22px;
    margin: 0px 0 15px 0;
  }

  .lb-bg {
    height: calc(100% - 40px);
    color: $secondary-text;
    line-height: 40px;
  }

  i {
    margin-right: 15px;
  }

  a {
    color: $secondary-text;
    text-decoration: none;

    &:hover {
      color: $secondary-color;
    }
  }

  &.sp {
    @media only screen and (max-width:1110px) {
      h3 {
        min-height: 55px;
      }
    }

    @media only screen and (max-width:767px) {
      h3 {
        min-height: 10px;
      }
    }
  }
}

/* History page */
.history {
  padding-right: 80px;
  padding-left: 80px;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 290px;
    left: 0px;
    width: 100%;
    height: 6px;
    background: $primary-color;
  }

  .owl-carousel {

    .owl-prev,
    .owl-next {
      width: 45px;
      height: 45px;
      background: $white;
      border-radius: $border-radius-primary;
      position: absolute;
      top: 264px;
      box-shadow: $box-shadow;
      font-family: "Font Awesome 6 Pro";
    }

    .owl-prev {
      left: -60px;
      overflow: hidden;
      text-indent: -200px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M15 19.75a.744.744 0 0 1-.53-.22l-7-7a.749.749 0 0 1 0-1.06l7-7a.75.75 0 0 1 1.06 1.06L9.061 12l6.469 6.47a.75.75 0 0 1-.53 1.28z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid $white;

      &:hover {
        background-color: $white;
        border-color: $primary-color;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M15 19.75a.744.744 0 0 1-.53-.22l-7-7a.749.749 0 0 1 0-1.06l7-7a.75.75 0 0 1 1.06 1.06L9.061 12l6.469 6.47a.75.75 0 0 1-.53 1.28z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.disabled {
        background: #fff !important;
        opacity: 1;

        &:after {
          position: absolute;
          top: 0px;
          left: 0px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M15 19.75a.744.744 0 0 1-.53-.22l-7-7a.749.749 0 0 1 0-1.06l7-7a.75.75 0 0 1 1.06 1.06L9.061 12l6.469 6.47a.75.75 0 0 1-.53 1.28z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
          background-repeat: no-repeat;
          background-position: 5px 5px;
          width: 45px;
          height: 45px;
          content: "";
          opacity: .2;
        }

        &:hover {
          border-color: $white;
        }
      }
    }

    .owl-next {
      right: -60px;
      overflow: hidden;
      text-indent: -200px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M42.7 29.6 26.2 13.1l-2.4-2.4c-1.8-1.8-4.7 1-2.8 2.8L37.5 30l.9.9-15 15-2.3 2.3c-1.8 1.8 1 4.7 2.8 2.8l16.4-16.4 2.3-2.3c.9-.6.9-1.9.1-2.7z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid $white;

      &:hover {
        background-color: $white;
        border-color: $primary-color;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M42.7 29.6 26.2 13.1l-2.4-2.4c-1.8-1.8-4.7 1-2.8 2.8L37.5 30l.9.9-15 15-2.3 2.3c-1.8 1.8 1 4.7 2.8 2.8l16.4-16.4 2.3-2.3c.9-.6.9-1.9.1-2.7z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.disabled {
        background: #fff !important;
        opacity: 1;

        &:after {
          position: absolute;
          top: 0px;
          left: 0px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M42.7 29.6 26.2 13.1l-2.4-2.4c-1.8-1.8-4.7 1-2.8 2.8L37.5 30l.9.9-15 15-2.3 2.3c-1.8 1.8 1 4.7 2.8 2.8l16.4-16.4 2.3-2.3c.9-.6.9-1.9.1-2.7z" fill="%2300577A" data-original="%23000000" class=""></path></g></svg>');
          background-repeat: no-repeat;
          background-position: 5px 5px;
          width: 45px;
          height: 45px;
          content: "";
          opacity: .2;
        }

        &:hover {
          border-color: $white;
        }
      }
    }

    h2 {
      color: $secondary-color;
      font-size: 30px;
      padding: 30px 0;
      margin: 0;
    }


    .owl-item {
      font-size: 14px;
      line-height: 24px;

      .h-img-b {
        .ht-info {
          position: relative;
          padding-bottom: 30px;

          .ht-text {
            height: 180px;
            overflow: auto;
          }


          &:before {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: -2px;
            left: 0px;
            background: $primary-color;
            border-radius: 15px;
          }

          p {
            &:last-child {
              margin: 0;
            }
          }
        }

        .ht-img {
          position: relative;
          margin-left: -50%;
          margin-top: 35px;

          img {
            max-width: 100%;
            max-height: 240px;
            width: auto;
          }

        }
      }

      .h-img-t {
        .ht-info {

          position: relative;

          &:before {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: -32px;
            left: 0px;
            background: $primary-color;
            border-radius: 15px;
          }


          p {
            &:last-child {
              margin: 0;
            }
          }

          .ht-text {
            overflow: auto;
          }

        }

        .ht-img {
          position: relative;
          min-height: 310px;

          img {
            max-width: 100%;
            border-radius: $border-radius-secondary;
            max-height: 240px;
            width: auto;
          }


        }
      }
    }

  }

  @media only screen and (max-width:1024px) {
    .owl-carousel .owl-item .h-img-b .ht-img {
      margin-left: 0;
    }
  }
}

/* News Releases Page */
.news-rel-detail{
    word-break: normal;
    hyphens: auto;
}
.news-releases {
  .datatable-body {
    a {
      color: $primary-text;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $primary-color;
      }
    }
  }

  .ngx-datatable.material {
    margin-top: 25px;
  }

  .ngx-datatable .datatable-header {
    display: none;
  }

  .ngx-datatable.material {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0), 0 8px 10px 1px rgba(0, 0, 0, 0), 0 3px 14px 2px rgba(0, 0, 0, 0);
  }

  .datatable-row-wrapper {
    border: 1px solid #d4d6d9;
    border-radius: $border-radius-primary;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 18px;
    color: $secondary-text;
    border-right: 1px solid #d4d6d9;
    min-width:132px;
  }

  .ngx-datatable.material.striped .datatable-row-even {
    background: #fdfeff;
  }

  .ngx-datatable.material.striped .datatable-row-odd {
    background: #eff6fc;
  }

  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background: #edf5fc;
  }
}

.pager_nav {
  ul {
    margin: 0;
    padding: 0;
    text-align: right;

    li {
      list-style: none;
      display: inline-block;
      margin: 0 5px;
      color: $secondary-text;

      &:last-child {
        margin-right: 0px;
      }

      a {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: $white;
        border: 1px solid $primary-color;
        display: block;
        border-radius: $border-radius-primary;
        color: $primary-color;

        &:hover {
          background: $primary-color;
          color: $white;
        }

        &.disabled {
          opacity: 0.5;
          border-color: $gray;
          color: $gray;
          cursor: inherit;

          &:hover {
            border-color: $gray;
            color: $gray;
            background: $white;
          }
        }
      }

    }
  }
}

/* Event Details */
.event-details {
  table {
    width: 100%;

    th,
    td {
      border: 1px solid #d4d6d9;
      padding: 10px 15px;
      min-width: 150px;
    }

    th {
      color: $secondary-text;
      white-space: nowrap;
      background: #eff6fc;
    }

  }
}

/* Tab Nav */
.nav-pills {
  background: $white;
  border-radius: $border-radius-primary;
  padding: 10px;
  display: inline-block;
  flex-wrap: inherit;

  li {
    display: inline-block;
  }

  .nav-link {
    text-transform: capitalize;
    font-size: 18px;
    color: $secondary-text;
    border-radius: 0;

    &:hover {
      color: $primary-color
    }
  }

  .nav-link.active {
    background: $secondary-color;
    color: $white;
  }
}

.pill-lb {
  background: $light-blue;

  .nav-link {
    text-transform: capitalize;
    font-size: 18px;
    color: $primary-color;

    &:hover {
      color: $secondary-color;
    }
  }

  .nav-link.active {
    background: $secondary-color;
    color: $white;
  }
}

/* Accepted materials page */
.am-item {

  ul {
    padding-left: 22px;

    li {
      font-weight: 600;
      font-size: 22px;
      color: $secondary-text;
      padding: 8px 0;
    }
  }

  .f-weight-n {
    li {
      font-weight: normal;
      font-size: 18px;
    }
  }

  .note {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    margin-top: 5px;
  }
}

.blue-bg {
  .am-item {
    li {
      color: $white;
    }
  }
}

/* Buy From Us */
.our-products {
  img {
    border-radius: 0px 0px 0 0;
  }

  .shadow {
    box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05) !important;
  }

  .p-4 {
    padding: 30px !important;

    p {
      margin: 0px;
    }
  }

  h3 {
    padding: 0 0 0px 0;
    margin: 0px 0 20px 0;
    position: relative;

    &:after {
      content: "";
      display: block;
      margin-top: 20px;
      width: 100px;
      height: 5px;
      border-radius: $border-radius-primary;
      background: $secondary-color;
    }
  }

}

.grn-steel {
  .g-logo {
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 1;
    max-width: 310px;
    object-fit: inherit;
    height: auto;
  }

  h2 {
    color: #33BF12;
  }

  h4 {
    margin-bottom: 10px;
  }

  h4,
  b {
    font-weight: 600;
  }
}

/* Services */
.service-gird {
  margin-top: 0px;

  h3 {
    margin-left: 30px;
    margin-bottom: 0px;
  }

  .lb-bg {
    padding: 30px;
  }

  .d-flex {
    margin-bottom: 20px;
  }

  ul {
    margin-left: 20px;
    margin-top: 20px;
    color: $secondary-text;
  }
}

.contact-our-team {
  background: url(/assets/images/services/contact-our-team.jpg) no-repeat center center / cover;
  padding: 50px;
  border-radius: $border-radius-secondary;
  border: 1px solid $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
  color: $white;
  font-size: 22px;

  .col-md-6,
  .col-12 {
    margin-bottom: 30px;
  }

  label {
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
  }

  .alert.alert-danger {
    background: transparent;
    border: 0px;
    padding: 0px;
    margin-bottom: 0;
    color: $secondary-color;
  }
}


/* Accordion */


.accordion {
  background: transparent;
  font-size: 22px;
}

.accordion-header {
  &:focus {
    border: 0;
    box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 0px 0px #DDDDDD;
  }


  a {
    background: rgba(255, 255, 255, 0.05);
    color: $white;
    border-radius: $border-radius-secondary !important;
    font-size: 22px;
    padding: 15px 50px 15px 25px;
    display: flex;
    text-decoration: none;
    font-weight: normal;
    position: relative;
    align-items: center;

    svg {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
    img {margin-right: 15px; width:35px;}
   
    .fa-arrow-right {
      position: absolute;
      right: 25px;
    }
  }
}

.accordion-button {
  background: rgba(255, 255, 255, 0.05);
  color: $white;
  border-radius: $border-radius-secondary !important;
  font-size: 22px;
  padding: 15px 25px;

  svg {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }
  img {margin-right: 15px; width:35px;}

  &:not(.collapsed) {
    background: rgba(255, 255, 255, 0.05);
    border: 0;
    border-radius: 0px 0px 0px 0px !important;
    color: $white;
    box-shadow: inset 0px 0px 0px 0px #ABABAB;

    &:after {
      content: "-";
      background: transparent;
      transform: inherit
    }
  }

  &:after {
    background: transparent;
    content: "+";
    font-size: 35px;
    margin-top: -25px;
    transform: inherit
  }
}

.accordion-item {
  border: 0px;
  border-radius: $border-radius-secondary !important;
  background: transparent;
  margin: 0px 0px 15px 0;
}

.accordion-body {
  background: rgba(255, 255, 255, 0.05);
  color: $white;
  padding: 10px 30px 30px 30px;
  border-radius: 0px 0px 0px 0px;
  font-size: 18px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding-left: 35px;
      margin-bottom: 20px;

      i {
        position: absolute;
        top: 5px;
        left: 0px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.nsm-content {
  border-radius: $border-radius-secondary !important;
  padding: 0% !important;
}


.bullet-arrow {
  margin: 0x;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 10px 0 10px 40px;

    .fa-arrow-right {
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
}

/* Legal Notices */
.legal-notices {
  table {
    min-width: 800px;

    th {
      background: $white !important;
      color: $secondary-text;
    }

    th,
    td {
      padding: 15px;
    }
  }

  .tc-secondary {
    margin-top: 40px;
  }

  ul {
    li {
      list-style: none;
      padding: 8px 0 8px 30px;
      position: relative;

      i {
        position: absolute;
        top: 13px;
        left: 0px;
        color: $secondary-text;
      }
    }
  }
}

.form-group {
  margin-bottom: 1.5rem;

  label {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }

  .text-danger {
    padding: 5px 0 0 0;
  }

}

/* Location Search */
.location-search {
  label {
    font-weight: 600;
    color: $secondary-text;
    padding-right: 10px;
  }

  .form-control {
    display: inline-block;
    width: auto;
    background-color: #F2F4FB;
    border: 0px;
    margin: 0 15px 0 0px;
  }
}

.col-lg-6 {
  padding-bottom: 1.5rem;
}

.lsr {
  .border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  .lr-brand-image {
    min-width: 150px;
    max-width: 150px;
    text-align: center;

    img {
      max-width: 100%;
      vertical-align: middle;
    }

  }

  .lr-info {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid #dee2e6;
    padding: 30px;
    width: 100%;
    height: 100%;

    h3 {
      font-size: 24px;
      margin: 0px;
      padding: 0px 100px 15px 0;
    }

    .lr-subtitle {
      font-weight: 600;
      color: $secondary-text;
    }

    i {
      margin-right: 10px;
    }
  }

  .lr-miles {
    align-self: baseline;
    white-space: nowrap;
    padding: 30px 30px 0 0;
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.form-select {
  padding-right: 40px;
}

/* Sustainability Report */
.sustainability-report {
  #content .blue-bg a {
    color: #fff;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding-left: 30px;
      margin-bottom: 15px;

      i {
        position: absolute;
        top: 5px;
        left: 0px;
      }
    }
  }

  img {
    max-width: 100%;

  }

  #content .right_col img {
    min-width: auto;
    box-shadow: 0px 5px 35px 5px rgba(0, 0, 0, 0.0);
  }

  h4 {
    font-size: 22px !important;
    color: $white;
  }

  h3 {
    img {
      margin: 0 !important;
    }
  }

  &:after {
    clear: both;
    content: "";
    display: block;
  }

  #safety #header,
  #environmental #header {
    color: #fff;
    font-weight: 700;
    padding: 10px 20px;
  }

  p {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

/* Blue Accordion */
.lb-accordion {
  .accordion {
    background: transparent;
    font-size: 22px;
  }

  .accordion-header {
    &:focus {
      border: 0;
      box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 0px 0px #DDDDDD;
    }
  }

  .accordion-button {
    background: $light-blue;
    color: $secondary-text;
    border-radius: $border-radius-secondary !important;
    font-size: 22px;
    padding: 15px 25px;

    svg {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }

    &:not(.collapsed) {
      background: $light-blue;
      color: $secondary-text;
      box-shadow: inset 0px 0px 0px 0px #ABABAB;

      &:after {
        content: "-";
        background: transparent;
        transform: inherit
      }
    }

    &:after {
      background: transparent;
      content: "+";
      font-size: 35px;
      margin-top: -25px;
      transform: inherit
    }
  }

  .accordion-item {
    border: 0px;
    background: transparent;
    margin: 0px 0px 15px 0;
  }

  .accordion-body {
    background: rgba(255, 255, 255, 0.05);
    color: $primary-text;
    padding: 10px 30px 30px 30px;
    border-radius: 0px 0px 0px 0px;
    font-size: 18px;
  }
}

/* Site Map */
.site-map {
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
  }

  .head {
    color: $white;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    &:after {
      background: $black;
      opacity: 0.6;
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      z-index: 2;
      left: 0;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      object-fit: cover;
    }

    h2 {
      z-index: 3;
      position: relative;
      font-size: 40px;
      color: $white;
      width: 100%;
      text-align: center;
      margin: 0px;
      padding: 0;
    }
  }

  ul {
    margin: 0;
    padding: 30px;

    li {
      list-style: none;
      position: relative;
      color: $secondary-text;
      padding: 7px 0 7px 30px;
      position: relative;

      i {
        position: absolute;
        top: 14px;
        left: 0px;
        font-size: 16px;
      }

      a {
        color: $secondary-text;
        text-decoration: none;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  @media only screen and (max-width:992px) {
    .head h2 {
      font-size: 28px;
    }
  }

  @media only screen and (max-width:767px) {
    .head {
      height: 100px;
    }

    .head h2 {
      font-size: 24px;
    }

    .pb0 {
      padding-bottom: 0;
    }

    .pt0 {
      padding-top: 0;
    }
  }
}

@media only screen and (max-width:1400px) {
  .pr-90 {
    padding-right: 15px;
  }
}

@media only screen and (max-width:1024px) {

  .container,
  header .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  #cookie-footer {
    right: 30px;
  }

  .carousel-inner {
    img {
      max-width: 100%;
    }
  }

  .ptb-70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pt-70 {
    padding-top: 40px;
  }

  .services {
    .col-md-3 {
      margin-bottom: 30px;
    }

    .col-md-3,
    .col-md-9 {
      width: 100%;
    }

  }

  .title h1 {
    font-size: 80px;
    line-height: 120px;
  }

  .theme1 #location-search-button {
    padding: 12.5px 15px 12px 15px !important;
  }

  .blue-bg-sq-big:after {
    display: none;
  }

}

@media only screen and (max-width:992px) {
  .title h1 {
    font-size: 80px;
    line-height: 80px !important;
  }

  .title span {
    font-size: 35px;
    line-height: 35px !important;
  }

  .pr-90 {
    padding-right: 15px;
  }

  .page-head h1 {
    font-size: 40px;
  }

  .gird-blocks a h4 {
    font-size: 24px;
  }

  .location-list .tab-content .location_column1 {
    width: 50%;
  }

  .fl-search {
    display: block !important;
  }

  .fl-search div .form-control {
    width: 100%;
    margin-top: 10px;
  }

  .board-committees h3 {
    min-height: auto;
  }
  .google_map{width:auto !important; height:auto; margin-top:30px;}
}

@media only screen and (max-width:767px) {
  .theme1 {
    height: auto !important;

    label {
      margin-top: 15px;
    }
  }

  .footer-nav,
  .row.footer-text {
    text-align: center;
  }

  footer h6 a {
    font-size: 16px;
  }

  #cookie-footer {
    right: auto;
    width: 100%;
    left: 0;
  }

  .fal-h h1 {
    font-size: 25px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .head-vid {
    height: 450px;

    .title {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .gird-blocks a {
    height: 150px;
  }

  .gird-blocks img {
    height: 100%;
    object-fit: cover;
  }

  .page-head .row {
    min-height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .page-head img {
    position: relative;
    width: 100%;
    left: 0;
  }

  .title-secondary,
  h3 {
    font-size: 25px;
  }

  .highlights h3 {
    font-size: 24px;
  }

  .highlights h3 img {
    max-width: 80px;
  }

  .half-img {


    .row {
      min-height: auto;

      .col-md-6 {
        padding-left: 15px;
      }
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
      object-fit: inherit;
      margin-bottom: 40px;
      border-radius: $border-radius-secondary;
    }
  }

  .half-img.grn-steel .row .col-md-6 {
    position: relative;
  }

  .grn-steel .g-logo {
    top: 20px;
    left: 20px;
  }

  .awards .plr-120 {
    padding: 0 30px;
  }

  .sub-nav {
    display: none;
  }

  .am-item ul li {
    font-size: 18px;
  }

  .home-title {
    max-width: 100%;
  }
}

@media only screen and (max-width:576px) {

  .container,
  header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }

  .top-nav {
    margin-bottom: 5px;

    ul li {
      margin: 0 5px;
      font-size: 12px;
    }
  }

  .navbar-brand {
    width: 100%;
    max-width: 200px;
  }

  .navbar-brand img {
    max-width: 200px;
  }

  #location-search-button {
    width: 100% !important;
    text-align: center;
  }

  .find-location .position-relative button {
    max-width: 70px;
  }

  .title h1 {
    font-size: 40px;
    line-height: 40px !important;
    margin-bottom: 20px;
  }

  .title span {
    font-size: 25px;
    line-height: 25px !important;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  .ptb-90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pb-90 {
    padding-bottom: 40px;
  }

  .pt-90 {
    padding-top: 40px;
  }

  .circular-economy .row {
    min-height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .play-btn img {
    max-width: 100px;
  }

  .f-22 {
    font-size: 18px;
  }

  .company-gird {
    .d-flex {
      display: block !important;
      text-align: center;
    }

    .bg-white {
      margin: 0 0 30px 0;
      display: inline-block;
    }
  }

  .modal-lg {
    max-width: 880px;

    .modal-body p:last-child{margin-bottom:0;}

    .btn-close {
      right: 10px;
      top: 10px;
    }
  }

  .fl-drak {
    padding: 40px 30px;

    h2 {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .nav-pills {
    display: block;
    width: 100%;

    li {
      display: block;

      button {
        width: 100%;
        text-align: left;
      }
    }
  }

  .location-list .tab-content .location_column1 {
    width: 100%;
  }

  .service-gird h3 {
    margin: 20px 0 0 0;
  }

  .service-gird ul.d-flex {
    text-align: left;
  }

  .contact-our-team {
    padding: 25px;
    font-size: 18px;
  }

  .lsr .border {
    display: block;
  }

  .lsr .lr-brand-image {
    padding: 30px 0 0 30px;
  }

  .lsr .lr-info {
    border: 0;
    height: auto;
  }

  .lsr .lr-info h3 {
    padding-right: 0;
    font-size: 20px;
  }

  .location-search {
    display: block !important;
  }

  .location-search .form-control {
    width: 100%;
  }

  .location_search_buttons .btn-secondary {
    width: 100%;
  }

  .owl-nav .owl-prev {
    left: -10px;
  }

  .owl-nav .owl-next {
    right: -10px;
  }
}

/* Español Media CSS */
@media only screen and (max-width:1180px) {
  .navbar.sp .nav-item {
    margin: 0 0px;
  }
}
@media only screen and (max-width:370px) {
.contact-our-team iframe{transform: scale(0.7);
position: absolute;
left:0px;}
}  
@media only screen and (max-width:280px) {
.contact-our-team iframe{transform: scale(0.5);
position: absolute;
left:-30px;}
}  
